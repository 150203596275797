body {
  /* padding: 12px 24px; */
  letter-spacing: 0.05em;
  color: white;
}

h1, h2, p {
  padding: 0;
  margin: 6px 0 0 0;
}

h2 {
  font-size: 1.5em;
}

ul, li {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 8px;
}

.username {
  font-weight: 700;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  font-size: 0.8em;
  background-color: rgb(22, 0, 41);
  padding: 18px;
  border-radius: 6px;
  width: 360px;
  box-shadow: 1px 6px 12px -9px rgba(0, 0, 0, 0.3);
}

.alert {
  background-color: green;
}

.redeemed {
  display: none;
}

.card-icon {
  margin-right: 8px;
}
.card-info {
  padding: 0 8px;
  width: 200px;
}
.card-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.8em;
  font-weight: 700;
  margin: 0 auto;
  width: 80px;
}
.card-time p {
  text-align: center;
  margin: 12px auto;
}
.card-cost {
  display: flex;
  flex-direction: column-reverse;
  font-size: 0.7em;
  text-align: center;
  margin: 0 auto;
  width: 80px;
}
.card-cost img {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}